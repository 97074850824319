import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import AudioNoteListItem from './AudioNoteListItem';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import ListOrNoElements from '../ListOrNoElements';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import TextNoteListItem from './TextNoteListItem';
import FileListItem from './FileListItem';

export default function NoteList ({
  notes,
  audioNotesOwner,
  onDeleteAudioNote,
  onDeleteTextNote,
  onDeleteFile,
  toggleEntryVisibility,
  isRetrievingData,
  tags,
  updateEntryTags,
  updateEntryTitle,
  listStyles
}) {
  const theme = useTheme();
  const style = styles(theme);

  const renderListElement = (note, index) => {
    return (
      <Box key={`note-${index}`}>
        {note.isAudio && <AudioNoteListItem
          note={note}
          owner={audioNotesOwner}
          tags={tags}
          updateEntryTags={updateEntryTags}
          updateAudioNoteTitle={updateEntryTitle}
          toggleEntryVisibility={toggleEntryVisibility}
          deleteNote={() => onDeleteAudioNote(note)}/>
        }
        {note.isText && <TextNoteListItem note={note}
                                          updateEntryTags={updateEntryTags}
                                          tags={tags}
                                          updateTextTitle={updateEntryTitle}
                                          toggleEntryVisibility={toggleEntryVisibility}
                                          deleteNote={() => onDeleteTextNote(note)}/>}
        {note.isFile && (
          <FileListItem note={note}
                        tags={tags}
                        deleteNote={() => {
                          onDeleteFile(note);
                        }}
                        toggleEntryVisibility={toggleEntryVisibility}
                        updateEntryTags={updateEntryTags}
                        updateFileName={updateEntryTitle}
                        updateFileTitle={updateEntryTitle}/>)}
        {index < notes.length - 1 && <Divider component="li"/>}
      </Box>
    );
  };

  return (
    <ListOrNoElements noElementIcon={SearchOffIcon}
                      elements={notes}
                      isRetrievingData={isRetrievingData}
                      listStyle={{ ...style.notesList, ...listStyles }}
                      renderElement={renderListElement}
                      noElementMessage={'No hay notas todavía!'}/>
  );
}

const styles = (theme) => ({
  notesList: {
    width: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: '5px',
    gap: '4px',
    marginBottom: '2rem',
  },
  noElementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    borderRadius: '5px',
    color: theme.colors.white,
    backgroundColor: theme.colors.lightBackground,
    border: `1px solid ${theme.colors.gray}`,
    width: '100%',
  }
});