import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from "./Line";
import LineButton from "./buttons/LineButton";
import GoogleIcon from "@mui/icons-material/Google";
import {Link} from "react-router-dom";
import {useState} from "react";

export default function LoginBox({
                                   login, subtitle,
                                   pathToOtherLogin,
                                   subtitleOtherLoginLink,
                                   fontColor = undefined
                                 }) {
  const theme = useTheme();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const style = styles(theme);

  fontColor = fontColor || theme.text.primary;

  const onLogin = async () => {
    await setIsLoggingIn(true);
    await login();
    await setIsLoggingIn(false);
  }

  return (
    <div style={style.loginBoxContainer}>
      <Typography variant="h4" color={fontColor}>
        M.A.I.A
      </Typography>
      <Line/>
      <Typography variant="body1" color={fontColor}>
        {subtitle}
      </Typography>
      <LineButton onClick={onLogin} disabled={isLoggingIn}
                  fullWidth light extraStyles={{color: fontColor}}>
        <GoogleIcon/> Google
      </LineButton>
      <Link style={{
        textDecoration: 'none',
        marginTop: '1rem',
        color: theme.text.darkGrey
      }}
            to={pathToOtherLogin}>
        {subtitleOtherLoginLink}
      </Link>
    </div>
  )
}

const styles = (theme) => ({
  loginBoxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.colors.darkGrey,
    minWidth: 300,
    minHeight: 300,
    padding: '2rem',
  }
});