import Typography from "@mui/material/Typography";
import {useApplication} from '../providers/ApplicationProvider';
import {useTheme} from "@mui/material";
import {observer} from 'mobx-react';
import DashboardMainContainer from "../components/DashboardMainContainer";

function HomeScreen() {
  const application = useApplication();
  const theme = useTheme();

  return (
    <DashboardMainContainer>
      <section>
        <Typography color={theme.text.primary} variant="h5" fontWeight={'800'}>
          👋 Buenos días {application.user.fullName()}!
        </Typography>
        <Typography color={theme.text.primary} variant="h6">
          Tené un gran gran día.
        </Typography>
      </section>
    </DashboardMainContainer>
  );
}

export default observer(HomeScreen);