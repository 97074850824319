import MaiaApiClient from './communication/MaiaApiClient';
import { FakeRequester } from '@eryxcoop/appyx-comm';
import BearerAuthorizationManager from './BearerAuthorizationManager';
import { SessionStore } from './SessionStore';
import { LocalStorage } from './LocalStorage';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import { action, computed, makeObservable, observable } from 'mobx';
import NotebookConfiguration from './NotebookConfiguration';
import { User } from './users/User';
import { extractDomainUrl } from '../libs/utils';
import MaiaApiRequester from './MaiaApiRequester';
import FirebaseAuthenticationMethod from './authentication/FirebaseAuthenticationMethod';
import NotificationReceiver from './NotificationReceiver';

export default class Application {
  constructor() {
    this._sessionStore = new SessionStore(new LocalStorage());
    this._session = undefined;
    this._maiaApiClient = this._setUpApiClient();
    this._firebaseAuthentication = new FirebaseAuthenticationMethod(this);
    this._notebookConfiguration = new NotebookConfiguration(this);
    this._notificationReceiver = new NotificationReceiver();
    this._loaded = false;

    makeObservable(this, {
      _loaded: observable,
      _session: observable,
      _finishedLoading: action,
      session: computed,
      user: computed,
      loaded: computed,
      userIsLoggedIn: computed,
      updateUserToken: action,
      load: action,
    });
  }

  async load() {
    this._session = await this._sessionStore.load();
    await this._firebaseAuthentication.subscribeToAuthEvents();
    this._finishedLoading();
  }

  async logOut() {
    this._sessionStore.remove();
    this._session.close();
    await this._firebaseAuthentication.signOut();
  }

  async logInDoctor(onError, onSuccess) {
    await this._loginUser({
      onError,
      onSuccess,
      login: (token, loginResponseHandler) => this.apiClient().loginDoctor(token, loginResponseHandler),
      createUser: ({fullName, email}) => User.newDoctor({fullName, email})
    });
  }

  async logInPatient(onError, onSuccess) {
    await this._loginUser({
      onError,
      onSuccess,
      login: (token, loginResponseHandler) => this.apiClient().loginPatient(token, loginResponseHandler),
      createUser: ({fullName, email}) => User.newPatient({fullName, email})
    });
  }

  async refreshUserToken() {
    await this._firebaseAuthentication.refreshUserToken();
  }

  async _loginUser({onError, onSuccess, login, createUser}) {
    const token = await this._firebaseAuthentication.signIn();
    try {
      const loginResponseHandler = new ApiResponseHandler({
        handlesSuccess: (response) => {
          const profile = response.profile();
          const newUser = createUser({fullName: profile.fullName(), email: profile.email()});
          this._session.loginUser({token: token, user: newUser});
          this._sessionStore.store(this._session);
          onSuccess();
        },
        handlesError: () => {
          onError();
        }
      });
      login(token, loginResponseHandler);
    } catch (_) {
      onError();
    }
  }

  apiClient() {
    return this._maiaApiClient;
  }

  _setUpApiClient() {
    const requester = this._setUpRequester();
    return new MaiaApiClient(requester);
  }

  _setUpRequester() {
    if (this._isUsingFakeApi()) {
      return new FakeRequester({waitingTime: 2000});
    }
    const authorizationManager = new BearerAuthorizationManager(this);
    const remoteApiUrl = process.env.REACT_APP_API_URL;
    return new MaiaApiRequester(this, remoteApiUrl, authorizationManager);
  }

  notificationReceiver() {
    return this._notificationReceiver;
  }

  notebookConfiguration() {
    return this._notebookConfiguration;
  }

  updateUserToken(token) {
    this._session.updateToken(token);
  }

  get user() {
    return this._session.user;
  }

  get session() {
    return this._session;
  }

  get userIsLoggedIn() {
    return this._session.isLoggedIn;
  }

  get loaded() {
    return this._loaded;
  }

  patientLoginUrl() {
    const currentHref = window.location.href;
    const domainUrl = extractDomainUrl(currentHref);
    return `${domainUrl}/login/patient`;
  }

  websocketUrl() {
    const baseUrl = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:8000/ws/";
    return baseUrl + this._session.token;
  }

  _finishedLoading() {
    this._loaded = true;
  }

  _isUsingFakeApi() {
    return process.env.REACT_APP_USE_FAKE_API === 'true';
  }
}