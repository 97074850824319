export default class OptionalField {
  constructor(field) {
    this._field = field;
  }

  get value() {
    return this._field.value;
  }

  get errors() {
    return this._field.errors;
  }

  get hasErrors() {
    return this._field.hasErrors;
  }

  get isValid() {
    if (!this._field.value || this._field.value === this.defaultValue()) {
      return true;
    }
    return this._field.isValid;
  }

  clear() {
    this._field.clear();
  }

  defaultValue() {
    return this._field.defaultValue();
  }

  updateValue(value) {
    this._field.updateValue(value);
  }

  validate() {
    this._field.validate();
  }

  get isRequired() {
    return false;
  }
}