import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import {action, computed, makeObservable, observable} from 'mobx';
import NotebookFeature from './NotebookFeature';
import {toast} from "react-toastify";
import {copyToClipboard} from "../libs/utils";

export default class MedicalRecordFeature extends NotebookFeature {
  constructor(patientId, application, voiceAudioRecorder) {
    super(application, voiceAudioRecorder);

    this._patient = undefined;
    this._patientId = patientId;
    this._searchingResults = false;
    this._patientSharedNotes = [];

    makeObservable(this, {
      _patient: observable,
      _patientSharedNotes: observable,
      _searchingResults: observable,
      _updatePatient: action,
      _updatePatientSharedNotes: action,
      patient: computed,
      searchEntries: action,
    });
  }

  _onLoad = () => {
    this._loadPatientSharedNotes();
  }

  copyInviteForPatient = async () => {
    const patientLoginUrl = this._application.patientLoginUrl();
    const onSuccess = () => toast.success("Invitación copiada correctamente.");
    const onError = () => toast.error("No se pudo acceder al portapapeles. Intente nuevamente.");
    await copyToClipboard(patientLoginUrl, onSuccess, onError);
  };

  mustShowPatientInvite = () => {
    return this._patient && !this._patient.has_registered;
  }

  searchEntries = async (searchQuery) => {
    this._searchingResults = true
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._searchedEntries = response.entries();
        this._searchingResults = false;
        this._showSearchResults = true;
      }
    });
    await this._application.apiClient().searchMedicalRecordEntries(this.patientId, searchQuery, responseHandler);
  }

  get isSearchingResults() {
    return this._searchingResults;
  }

  async _refreshNotebook() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._updatePatient(response.patient());
        const audioNotes = response.audioNotes();
        const textNotes = response.textNotes();
        const files = response.files();
        this._updateNotes(this._sortNotes(audioNotes, textNotes, files));
      }
    });
    this._application.apiClient().getPatientMedicalRecord(this._patientId, responseHandler);
  }

  async _saveTextNote(title, content, responseHandler) {
    await this._application.apiClient().createMedicalRecordTextNote(this._patientId, title, content, responseHandler);
  }

  _deleteEntry(entry, responseHandler) {
    this._application.apiClient().deleteMedicalRecordEntry(entry, this._patientId, responseHandler);
  }

  async _saveFile(file, responseHandler) {
    await this._application.apiClient().saveFileToMedicalRecord(this._patientId, file, responseHandler);
  }

  _updateEntryTitle(entry, title, responseHandler) {
    this._application.apiClient().updatePatientsEntryTitle(entry, title, this._patientId, responseHandler);
  }

  _updateEntryTags(entry, tags, responseHandler) {
    this._application.apiClient().updatePatientsEntryTags(entry, tags, this._patientId, responseHandler);
  }

  _sortNotes = (audioNotes, textNotes, files) => {
    return audioNotes.concat(textNotes).concat(files).sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
  }

  _loadPatientSharedNotes() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._updatePatientSharedNotes(response.notebookEntries())
      },
      handlesError: () => {
      }
    });
    this._application.apiClient().getPatientSharedNotebook(this._patientId, responseHandler);
  }

  get patientSharedNotes() {
    return this._patientSharedNotes
  }

  get patient() {
    return this._patient;
  }

  get patientId() {
    return this._patientId;
  }

  _updatePatient = (patient) => {
    this._patient = patient;
  }

  _updatePatientSharedNotes(notes) {
    this._patientSharedNotes = notes;
  }
}