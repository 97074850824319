import {computed, makeObservable, observable} from "mobx";
import TextField from "../libs/forms/fields/TextField";
import MaiaForm from "../libs/forms/MaiaForm";
import OptionField from "../libs/forms/fields/OptionField";
import EmailField from "../libs/forms/fields/EmailField";
import ApiResponseHandler from "@eryxcoop/appyx-comm/src/errors/ApiResponseHandler";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import DateField from "../libs/forms/fields/DateField";

export default class PatientProfileFeature {
  constructor(application) {
    this._application = application;
    this._form = undefined;

    makeObservable(this, {
      _form: observable,
      form: computed,
    })
  }

  load() {
    this.getPatientProfile();
  }

  _createFormFromProfile(profileResponse) {
    const biologicalSexOptions = this.biologicalSexOptionsSelect().map((option) => {
      return option.value;
    });

    const fields = {
      "firstName": new TextField(2, 20, profileResponse.firstName()),
      "lastName": new TextField(2, 20, profileResponse.lastName()).asOptional(),
      "email": new EmailField(profileResponse.email()),
      "biologicalSex": new OptionField(biologicalSexOptions, profileResponse.biologicalSex()).asOptional(),
      "birthdate": new DateField(profileResponse.birthdate() ? dayjs(profileResponse.birthdate()) : null).asOptional(),
      "identificationNumber": new TextField(3, 15, profileResponse.identificationNumber()).asOptional(),
    }

    return new MaiaForm(fields);
  }

  updatePatientProfile = () => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        toast.error("No se puedo actualizar la información.");
        this.getPatientProfile();
      },
      handlesSuccess: () => {
        toast.success("Tus datos fueron actualizados!")
      }
    });
    this._application.apiClient().updatePatientProfile(this.form.values, responseHandler);
  }

  getPatientProfile() {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        toast.error("No se puedo obtener el perfil.")
      },
      handlesSuccess: (response) => {
        this.form = this._createFormFromProfile(response.profile());
      }
    });
    this._application.apiClient().getPatientProfile(responseHandler);
  }

  biologicalSexOptionsSelect() {
    // this is repeated
    return [
      {label: 'Masculino', value: 'MALE'},
      {label: 'Femenino', value: 'FEMALE'},
      {label: 'Intersexual', value: 'INTERSEX'}
    ]
  }

  get isLoading() {
    return this._form === undefined;
  }

  get form() {
    return this._form;
  }

  set form(newForm) {
    this._form = newForm;
  }
}