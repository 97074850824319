
export default class Profile {
  static fromResponse(profileResponseContent) {
    return new Profile({
      fullName: profileResponseContent.full_name,
      firstName: profileResponseContent.first_name,
      lastName: profileResponseContent.last_name,
      email: profileResponseContent.email,
      biologicalSex: profileResponseContent.biological_sex,
      birthdate: profileResponseContent.birthdate,
      identificationNumber: profileResponseContent.identification_number
    });
  }

  constructor ({fullName, firstName, lastName, email, biologicalSex, birthdate, identificationNumber}) {
    this._fullName = fullName;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._biologicalSex = biologicalSex;
    this._birthdate = birthdate;
    this._identificationNumber = identificationNumber;
  }

  fullName() {
    return this._fullName;
  }

  firstName() {
    return this._firstName;
  }

  lastName() {
    return this._lastName;
  }

  email() {
    return this._email;
  }

  biologicalSex() {
    return this._biologicalSex;
  }

  birthdate() {
    return this._birthdate;
  }

  identificationNumber() {
    return this._identificationNumber;
  }

  isCompleted() {
    const nameIsCompleted = this.firstName() || this.lastName() || this.fullName();
    const anyOptionalInfoIsCompleted = this.birthdate() || this.identificationNumber() || this.biologicalSex();
    return nameIsCompleted && anyOptionalInfoIsCompleted;
  }
}