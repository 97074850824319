import { useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ValidateActionButtonDialog from '../dialogs/ValidateActionButtonDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import AudioDuration from '../AudioDuration';
import Markdown from 'react-markdown';
import { parseDate } from '../../libs/utils';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Link } from 'react-router-dom';
import TagsEditionBox from '../TagsEditionBox';
import MaiaIconButton from '../buttons/MaiaIconButton';
import { observer } from 'mobx-react';

function NotebookEntryListItem ({
  avatarIcon,
  linkToNote,
  note,
  tags,
  showBadges,
  deleteNote,
  linkShareInfo,
  updateTitle,
  updateEntryTags,
  toggleEntryVisibility,
  text
}) {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);
  const style = styles();

  const renderName = () => {
    return (
      <Typography
        variant="body1"
        color="text.primary"
        style={{ zIndex: 2 }}
        onClick={(e) => {
          e.preventDefault();
          setEditMode(true);
        }}
      >
        {note.title}
      </Typography>
    );
  };

  const changeTitleInput = () => {
    return (
      <input style={style.titleInput}
             type={'text'}
             autoFocus={true}
             onBlur={(e) => {
               const newName = e.target.value;
               updateTitle(note, newName);
               setEditMode(false);
             }}
             onKeyDown={(e) => {
               if (e.key === 'Enter') {
                 const newName = e.target.value;
                 updateTitle(note, newName);
                 setEditMode(false);
               }
             }}
             defaultValue={note.title}/>
    );
  };

  const showVisibilityIcon = () => {
    if(!toggleEntryVisibility) {
      return null;
    }

    const icon = note.isPublic ? PublicIcon : PublicOffIcon;
    return (
      <MaiaIconButton
        extraStyles={{ zIndex: 2 }}
        icon={icon}
        title={note.isPublic ? "Público" : "Privado"}
        onClick={(e) => {
        e.preventDefault();
        toggleEntryVisibility(note);
      }}/>
    );
  };

  const listItem = () => {
    return (
      <ListItem
        alignItems="flex-start"
        secondaryAction={
          <ValidateActionButtonDialog
            icon={DeleteIcon}
            iconColor={theme.colors.darkGrey}
            iconHoverTitle={'Eliminar nota'}
            acceptLabel={'Eliminar'}
            onConfirm={deleteNote}
            title={'Estás a punto de eliminar esta nota.'}
            subText={'¿Estás seguro de que deseas eliminarla?'}
          />
        }
      >
        {avatarIcon}
        <ListItemText
          primary={
            <Box sx={style.headerContainer}>
              {editMode ? changeTitleInput() : renderName()}
              {showBadges()}
              {showVisibilityIcon()}
            </Box>
          }
          secondary={
            <Box sx={style.subtitleContainer}>
              <Box sx={style.subtitleDate}>
                <Box sx={style.rowContainer}>
                  <CalendarMonthIcon/><Typography>{parseDate(note.creationDate)}</Typography>
                </Box>
                {note.isAudio && note.publicUrl && <Box sx={style.rowContainer}>
                  <TimelapseIcon/><AudioDuration publicUrl={note.publicUrl} showLabel={false}/>
                </Box>
                }
              </Box>
              <TagsEditionBox tags={tags} note={note} updateEntryTags={updateEntryTags}/>
              <Box sx={style.rowContainer}>
                <Typography sx={style.markdownText}>
                  <Markdown>
                    {text}
                  </Markdown>
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    );
  };

  if (editMode) {
    return listItem();
  }

  if (note.isFile && !note.isAnImage) {
    return <a
      style={style.link}
      href={note.publicUrl}
      target="_blank"
      rel={'noreferrer'}>
      {listItem()}
    </a>;
  }

  return (
    <Link style={style.link} to={linkToNote}
          state={linkShareInfo}>
      {listItem()}
    </Link>
  );
}

export default observer(NotebookEntryListItem);

const styles = () => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px'
  },
  rowContainer: {
    display: 'flex',
    gap: '5px'
  },
  markdownText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  titleInput: {
    background: 'none',
    border: 'none',
    fontSize: '1rem'
  },
  subtitleContainer: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  },
  subtitleDate: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  link: {
    display: 'flex',
    marginRight: '10px',
    textDecoration: 'none'
  }
});