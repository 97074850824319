import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import ListOrNoElements from '../ListOrNoElements';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import AvatarPlayButton from "../audio/AvatarPlayButton";
import SimpleNotebookEntryListItem from "./SimpleNotebookEntryListItem";
import AvatarTextNote from "../audio/AvatarTextNote";
import {FileAvatarIcon} from "../FileAvatarIcon";

export default function SimplifiedNoteList({
                                             notes,
                                             isRetrievingData,
                                             listStyles,
                                             audioNotesOwner
                                           }) {
  const theme = useTheme();
  const style = styles(theme);

  const renderListElement = (note, index) => {
    return (
      <Box key={`note-${index}`}>
        {note.isAudio && <SimpleNotebookEntryListItem
          avatarIcon={<AvatarPlayButton audioScr={note.publicUrl} audioOwner={audioNotesOwner}/>}
          linkToNote={audioNotesOwner.seeNotePath()}
          note={note}
          text={note.summaryContent}
          linkShareInfo={{audioEntryId: note.shared_id, owner: audioNotesOwner}}
        />
        }
        {note.isText && <SimpleNotebookEntryListItem
          avatarIcon={<AvatarTextNote/>}
          linkToNote={'text_note'}
          note={note}
          text={note.content}
          linkShareInfo={{textNoteId: note.shared_id}}
        />}
        {note.isFile && (
          <SimpleNotebookEntryListItem
            avatarIcon={<FileAvatarIcon note={note}/>}
            linkToNote={`image/${note.shared_id}`}
            note={note}
            text={note.summaryContent}
            linkShareInfo={{imagePath: note.publicUrl, creationDate: note.creationDate}}
          />)}
        {index < notes.length - 1 && <Divider component="li"/>}
      </Box>
    );
  };

  return (
    <ListOrNoElements noElementIcon={SearchOffIcon}
                      elements={notes}
                      isRetrievingData={isRetrievingData}
                      listStyle={{...style.notesList, ...listStyles}}
                      renderElement={renderListElement}
                      noElementMessage={'No se encontraron resultados'}/>
  );
}

const styles = (theme) => ({
  notesList: {
    width: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: '5px',
    gap: '4px'
  },
  noElementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    borderRadius: '5px',
    color: theme.colors.white,
    backgroundColor: theme.colors.lightBackground,
    border: `1px solid ${theme.colors.gray}`,
    width: '100%',
  }
});