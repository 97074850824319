import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {observer} from "mobx-react";
import MaiaIconButton from "./buttons/MaiaIconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyToClipboard} from "../libs/utils";
import {toast} from "react-toastify";

function CopyTextContainer({textForClipboard, children}) {
  const theme = useTheme();
  const style = styles(theme);

  const onCopy = async () => {
    const onSuccess = () => toast.success("Texto copiado al portapapeles.");
    const onError = () => toast.error(("No se pudo acceder al portapapeles. Intente nuevamente."));
    await copyToClipboard(textForClipboard, onSuccess, onError);
  };

  return (
    <Box style={style.boxContainer}>
      <div style={style.copyButtonContainer}>
        <MaiaIconButton icon={ContentCopyIcon}
                        title={'Copiar'}
                        onClick={onCopy}/>
      </div>
      <Typography color={theme.text.primary} variant="body1">
        {children}
      </Typography>
    </Box>
  )
}

export default observer(CopyTextContainer);

const styles = (theme) => ({
  boxContainer: {
    padding: '2rem',
    position: 'relative',
    backgroundColor:
    theme.colors.lightBackground,
    borderRadius: '0.2rem',
    borderWidth: '1px',
    borderColor:
    theme.palette.primary.main,
    borderStyle: 'solid',
  },
  copyButtonContainer: {
    position: 'absolute',
    right: '8px',
    top: '10px'
  }
});