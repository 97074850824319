import PersonIcon from "@mui/icons-material/Person";
import TransgenderIcon from "@mui/icons-material/Transgender";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BadgeIcon from "@mui/icons-material/Badge";
import EmailIcon from '@mui/icons-material/Email';
import {ageOfPersonFromDateString} from "../libs/utils";
import { Grid, useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import {observer} from "mobx-react";

function PatientInfoBox({patient}) {
  const theme = useTheme();
  const style = styles(theme);

  const fullName = `${patient?.first_name || ''} ${patient?.last_name || ''}`;
  const ageAsString = `${ageOfPersonFromDateString(patient?.birthdate)} años`;

  const renderPatientInfoRow = (icon, label, text) => {
    return (
      <div style={style.personalInfoRow}>
        <div style={style.iconContainer}>
          {icon}
        </div>
        <Typography variant="body1" color={theme.text.primary}>
          {label}: {text !== null ? text : 'No indicado'}
        </Typography>
      </div>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {renderPatientInfoRow(<PersonIcon style={style.iconColor}/>, 'Nombre', fullName)}
      </Grid>
      <Grid item xs={6}>
        {renderPatientInfoRow(<BadgeIcon
          style={style.iconColor}/>, 'DNI', patient.identification_number)}
      </Grid>
      <Grid item xs={6}>
        {renderPatientInfoRow(<DateRangeIcon
          style={style.iconColor}/>, 'Edad', ageAsString)}
      </Grid>
      <Grid item xs={6}>
        {renderPatientInfoRow(<TransgenderIcon
          style={style.iconColor}/>, 'Sexo', patient?.biological_sex)}
      </Grid>
      <Grid item xs={6}>
        {renderPatientInfoRow(<EmailIcon
          style={style.iconColor}/>, 'Email', patient?.email)}
      </Grid>
    </Grid>
  )
}

export default observer(PatientInfoBox);

const styles = (theme) => ({
  personalInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },
  iconContainer: {
    background: theme.colors.lightBlue,
    padding: '0.5rem',
    display: 'flex',
    borderRadius: '50%',
  },
  iconColor: {
    color: theme.colors.white,
    height: '0.8em',
    width: '0.8em',
  }
});