import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';
import ListAdapter from "../adapters/general/ListAdapter";
import NotebookEntryAdapter from "../adapters/NotebookEntryAdapter";

export default class GetEntriesResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        entries: [
          {
            "shared_id": "asdf-123",
            "tags": ["tag1"],
            "creation_date": "2021-01-01",
            "content": "asdf.wav",
            "id": "asdf-123"
          },
        ]
      },
    }
  }

  entries() {
    return new ListAdapter(this.content().entries, NotebookEntryAdapter).adapt()
  }
}