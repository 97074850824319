import { Avatar, ListItemAvatar, useTheme } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { FileDownload } from '@mui/icons-material';
import * as React from 'react';

export function FileAvatarIcon ({ note }) {
  const theme = useTheme();
  return (
    <ListItemAvatar>
      <Avatar sx={{
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {note.isAnImage ? <ImageIcon/> : <FileDownload/>}
      </Avatar>
    </ListItemAvatar>
  );
}