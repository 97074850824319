import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilledButton from './buttons/FilledButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import FormTextAreaField from './fields/FormTextAreaField';
import Markdown from 'react-markdown';
import { parseDate } from '../libs/utils';
import { useApplication } from '../providers/ApplicationProvider';
import { observer } from 'mobx-react';
import FormTextField from './fields/FormTextField';
import CopyTextContainer from './CopyTextContainer';

function TextNoteViewOrEdit ({ textNote, canBeEdited = true }) {
  const theme = useTheme();
  const application = useApplication();
  const [editMode, setEditMode] = useState(false);
  const style = styles(theme);

  const _onEdit = () => {
    textNote.save(application.apiClient());
    setEditMode(false);
  };

  const _onCancel = () => {
    textNote.update(textNote.content);
    setEditMode(false);
  };

  const editContainer = () => {
    return (
      <Box style={style.editContainer}>
        <FormTextField field={textNote.titleField} label={'Titulo'}/>
        <FormTextAreaField field={textNote.field} label={'Contenido'}/>
      </Box>
    );
  };

  return (
    <Box style={style.noteContainer}>
      <Box style={style.optionsBarContainer}>
        <Typography color={theme.text.primary}>
          {parseDate(textNote.creationDate)}
        </Typography>
        {
          canBeEdited && (
            <Box style={{ display: 'flex', gap: '10px', alignContent: 'center' }}>
              {editMode ?
                <>
                  <FilledButton onClick={_onEdit}
                                style={style.newSessionButton}
                                title={'Guardar edición'}>
                    <SaveIcon color="white"/>
                  </FilledButton>
                  <FilledButton onClick={_onCancel} style={style.newSessionButton}
                                title={'Cancelar edición'}>
                    <CancelIcon color="white"/>
                  </FilledButton>
                </>
                :
                <FilledButton onClick={() => setEditMode(true)} style={style.newSessionButton} title={'Editar nota'}>
                  <EditIcon color="white"/>
                </FilledButton>
              }
            </Box>)
        }
      </Box>
      {editMode ?
        editContainer()
        :
        <CopyTextContainer textForClipboard={textNote.content}>
          <Markdown>
            {textNote.content}
          </Markdown>
        </CopyTextContainer>
      }
    </Box>
  );
}

export default observer(TextNoteViewOrEdit);

const styles = (theme) => ({
  viewTextContainer: {
    padding: '2rem',
    backgroundColor:
    theme.colors.lightBackground,
    borderRadius: '0.2rem',
    borderWidth: '1px',
    borderColor:
    theme.palette.primary.main,
    borderStyle: 'solid',
  },
  optionsBarContainer: {
    padding: '1rem',
    backgroundColor: theme.colors.darkBackground,
    borderRadius: '0.2rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }
});