import {useTheme} from "@mui/material";
import MaiaIconButton from "./MaiaIconButton";
import * as React from "react";

export default function MaiaFilledIconButton({
                                               icon,
                                               title,
                                               backgroundColor = undefined,
                                               iconColor = undefined,
                                               disabled = false,
                                               onClick
                                             }) {
  const theme = useTheme();
  const style = styles(theme, iconColor, backgroundColor, disabled);
  iconColor = iconColor || theme.colors.white
  const Icon = icon;

  return (
    <MaiaIconButton
      icon={Icon}
      iconColor={iconColor}
      extraStyles={style.button}
      title={title}
      onClick={onClick}
    />
  )
}


const styles = (theme) => ({
  button: {
    backgroundColor: theme.colors.button,
    borderRadius: '5px',
    padding: '0.8rem'
  },
});