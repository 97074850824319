import {DateTime} from "luxon";

export function ageOfPersonFromDateString(birthDateString) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function secondsToMinutes(seconds) {
  return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, "0")}`;
}

export function pluralize(word, count) {
  if (count === 1) {
    return `${count} ${word}`; // Singular form
  } else {
    // Naive approach: Just add 's'
    return `${count} ${word}s`; // Plural form
  }
}

export function readableAudioDuration(audioDuration) {
  if (!audioDuration) {
    return 'Cargando...';
  }

  const seconds = Math.floor(audioDuration % 60);
  const minutes = Math.floor(audioDuration / 60);

  if (minutes > 0) {
    return `${pluralize('minuto', minutes)} y ${pluralize('segundo', seconds)}`;
  }
  return `${pluralize('segundo', seconds)}`;
}

export function parseDate(dateISO, invalidDateText = '...') {
  const date = DateTime.fromISO(dateISO);

  return date.isValid ? date.toFormat('dd/MM/yyyy HH:mm') : invalidDateText;
}

export function presentDate(date) {
  return date.toISOString().substring(0, 10);
}

export async function copyToClipboard(textForClipboard, onSuccess, onError) {
  try {
    const permissions = await navigator.permissions.query({name: "clipboard-write"})
    if (permissions.state === "granted" || permissions.state === "prompt") {
      await navigator.clipboard.writeText(textForClipboard);
      onSuccess();
    } else {
      onError();
    }
  } catch (error) {
    onError();
  }
}

export function extractDomainUrl(href) {
  const url = new URL(href);
  return url.origin;
}

export function dayAndHoursDistanceFromMessage(expirationDatetime) {
  const now = new Date();
  const diff = expirationDatetime - now;
  const numberOfSecondsInADay = 60 * 60 * 24;
  const days = Math.floor(diff / (1000 * numberOfSecondsInADay));
  const hours = Math.floor(diff / (1000 * 60 * 60) % 24);
  const minutes = Math.floor(diff / (1000 * 60) % 60);
  let timeString = days > 0 ? pluralize('día', days) : '';
  timeString += days > 0 && hours > 0 ? ' y ' : '';
  timeString += hours > 0 ? pluralize('hora', hours) : '';
  timeString += (hours > 0 || days > 0) && minutes > 0 ? ' y ' : '';
  timeString += minutes > 0 ? pluralize('minuto', minutes) : '';
  return timeString;
}

export function isDateFurtherThanDays(dateString, days) {
  const date = new Date(dateString);
  const now = new Date();
  const diff = date - now;
  return diff > days * 24 * 60 * 60 * 1000;
}