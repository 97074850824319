import {MenuItem, Select, useTheme} from "@mui/material";
import {observer} from "mobx-react";
import FormFieldBase from "./FormFieldBase";

function FormSelectField({label, disabled=false, field, options, extraLabelStyle = {}}) {
  const theme = useTheme();

  return (
    <FormFieldBase label={label} field={field} extraLabelStyle={extraLabelStyle}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        style={{
          backgroundColor: theme.fields.background,
          borderRadius: '5px',
        }}
        InputLabelProps={{shrink: false}}
        fullWidth
        disabled={disabled}
        size="small"
        // Add placeholder
        value={field.value}
        onChange={(e) => field.updateValue(e.target.value)}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          );
        })}
      </Select>
    </FormFieldBase>
  );

}

export default observer(FormSelectField);