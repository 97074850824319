import {ApiClient} from "@eryxcoop/appyx-comm";
import EndpointFactory from "./EndpointFactory";
import {presentDate} from "../../libs/utils";

export default class MaiaApiClient {

  constructor(requester) {
    this._apiClient = new ApiClient(requester);
    this._endpointFactory = new EndpointFactory();
  }

  async loginDoctor(googleToken, responseHandler) {
    const values = {
      token: googleToken
    }
    const endpoint = this._endpointFactory.loginDoctorEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async loginPatient(googleToken, responseHandler) {
    const values = {
      token: googleToken
    }
    const endpoint = this._endpointFactory.loginPatientEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  getDoctorsPatients(responseHandler) {
    const endpoint = this._endpointFactory.getDoctorsPatientsEndpoint();
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  startFirstMedicalConsultation(patient, responseHandler) {
    const values = {
      first_name: patient.firstName,
      last_name: patient.lastName,
      email: patient.email,
      identification_number: patient.identificationNumber,
      birthdate: presentDate(patient.birthdate),
      biological_sex: patient.biologicalSex
    }
    const endpoint = this._endpointFactory.createFirstMedicalConsultationEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  startMedicalConsultation(patient, responseHandler) {
    const values = {
      patient_id: patient.shared_id
    }
    const endpoint = this._endpointFactory.createMedicalConsultationEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  findPatientByIdentificationNumber(identificationNumber, responseHandler) {
    const endpoint = this._endpointFactory.findPatientByIdentificationNumber(identificationNumber);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  createMedicalRecordAudioNote(patient, recordingBlob, responseHandler) {
    const values = {
      audio_file: recordingBlob
    }
    const endpoint = this._endpointFactory.createMedicalRecordAudioNoteEndpoint(patient.id());
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  createAudioNote(recordingBlob, responseHandler) {
    const values = {
      audio_file: recordingBlob
    }
    const endpoint = this._endpointFactory.createAudioNoteEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async createTextNote(title, content, responseHandler) {
    const endpoint = this._endpointFactory.createTextNoteEndpoint();
    return this._apiClient.callEndpoint(endpoint, {title, content}, responseHandler);
  }

  async createMedicalRecordTextNote(patientId, title, content, responseHandler) {
    const values = {
      content: content,
      title: title
    }
    const endpoint = this._endpointFactory.createMedicalRecordTextNoteEndpoint(patientId);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async saveFileToMedicalRecord(identificationNumber, file, responseHandler) {
    const values = {file: file}
    const endpoint = this._endpointFactory.saveFileToMedicalRecordEndpoint(identificationNumber);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async saveFileToNotebook(file, responseHandler) {
    const values = {file: file}
    const endpoint = this._endpointFactory.saveFileToNotebookEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async getNotebookEntries(responseHandler) {
    const endpoint = this._endpointFactory.getNotebookEntriesEndpoint();
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  async getEntriesBySearch(responseHandler, searchQuery) {
    const endpoint = this._endpointFactory.getEntriesBySearchEndpoint(searchQuery);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  async searchMedicalRecordEntries(patientId, searchQuery, responseHandler) {
    const endpoint = this._endpointFactory.searchMedicalRecordEntriesEndpoint(patientId);
    return this._apiClient.callEndpoint(endpoint, {query: searchQuery}, responseHandler);
  }

  deleteNotebookEntry(entry, responseHandler) {
    const endpoint = this._endpointFactory.deleteNotebookEntryEndpoint(entry.shared_id);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  deleteMedicalRecordEntry(entry, patientId, responseHandler) {
    const endpoint = this._endpointFactory.deleteMedicalRecordEntryEndpoint(patientId, entry.shared_id);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getPatientMedicalRecord(patientId, responseHandler) {
    const endpoint = this._endpointFactory.getPatientMedicalRecordEndpoint(patientId);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getAudioNoteTranscription(id, responseHandler) {
    const endpoint = this._endpointFactory.getAudioNoteTranscriptionEndpoint(id);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getNotebookEntry(id, responseHandler) {
    const endpoint = this._endpointFactory.getNotebookEntry(id);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  editTextNoteContent(id, newTitle, newContent, responseHandler) {
    const values = {content: newContent, title: newTitle}
    const endpoint = this._endpointFactory.editTextNoteContentEndpoint(id);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  updatePatientsEntryTitle(entry, name, identificationNumber, responseHandler) {
    const values = {new_title: name}
    const endpoint = this._endpointFactory.updateMedicalRecordEntryTitleEndpoint(entry, identificationNumber);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  updatePatientsEntryTags(entry, tags, identificationNumber, responseHandler) {
    const values = {tags: tags.map(tag => tag.id)}
    const endpoint = this._endpointFactory.updateMedicalRecordEntryTagsEndpoint(entry, identificationNumber);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  updateNotebookEntryTitle(entry, name, responseHandler) {
    const values = {new_title: name}
    const endpoint = this._endpointFactory.updateNotebookEntryTitleEndpoint(entry);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  updateNotebookEntryTags(entry, tags, responseHandler) {
    const values = {tags: tags.map(tag => tag.id)}
    const endpoint = this._endpointFactory.updateNotebookEntryTagsEndpoint(entry);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  getNotebookConfiguration(responseHandler) {
    const endpoint = this._endpointFactory.getNotebookConfigurationEndpoint();
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  createNotebookTag(newTag, responseHandler) {
    const values = {name: newTag}
    const endpoint = this._endpointFactory.createNotebookTagEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  deleteNotebookTag(tagId, responseHandler) {
    const endpoint = this._endpointFactory.deleteNotebookTagEndpoint(tagId);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  editNotebookTag(tagId, newName, responseHandler) {
    const values = {new_name: newName}
    const endpoint = this._endpointFactory.editNotebookTagEndpoint(tagId);
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  async getPatientProfile(responseHandler) {
    const endpoint = this._endpointFactory.getPatientProfileEndpoint();
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  updatePatientProfile(patient, responseHandler) {
    const values = {
      first_name: patient.firstName,
      last_name: patient.lastName,
      identification_number: patient.identificationNumber,
      birthdate: patient.birthdate ? presentDate(patient.birthdate) : '',
      biological_sex: patient.biologicalSex
    }
    const endpoint = this._endpointFactory.updatePatientProfileEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  getDoctorInformation(email, responseHandler) {
    const values = {email};
    const endpoint = this._endpointFactory.getDoctorInformationEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  shareNotebookWithDoctor(doctorEmail, sharingTime, responseHandler) {
    const values = {
      email: doctorEmail,
      expiration_in_hours: sharingTime
    }
    const endpoint = this._endpointFactory.shareNotebookWithDoctorEndpoint();
    return this._apiClient.callEndpoint(endpoint, values, responseHandler);
  }

  removeSharePermissionToDoctor(doctorEmail, responseHandler) {
    const endpoint = this._endpointFactory.stopSharingNotebookWithDoctorEndpoint(doctorEmail);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  markNotebookEntryAsPrivate(entry, responseHandler) {
    const endpoint = this._endpointFactory.markNotebookEntryAsPrivateEndpoint(entry);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  markNotebookEntryAsPublic(entry, responseHandler) {
    const endpoint = this._endpointFactory.markNotebookEntryAsPublicEndpoint(entry);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getSharePermissions(responseHandler) {
    const endpoint = this._endpointFactory.getSharePermissionsEndpoint();
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getPatientSharedNotebook(patientId, responseHandler) {
    const endpoint = this._endpointFactory.getPatientSharedNotebookEndpoint(patientId);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }

  getSharedEntry(patientId, entryId, responseHandler) {
    const endpoint = this._endpointFactory.getSharedEntryEndpoint(patientId, entryId);
    return this._apiClient.callEndpoint(endpoint, {}, responseHandler);
  }
}