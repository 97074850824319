import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import AudioDuration from '../AudioDuration';
import Markdown from 'react-markdown';
import {parseDate} from '../../libs/utils';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

function SimpleNotebookEntryListItem({
                                       avatarIcon,
                                       linkToNote,
                                       note,
                                       linkShareInfo,
                                       text,
                                     }) {
  const style = styles();

  const listItem = () => {
    return (
      <ListItem
        alignItems="flex-start"
      >
        {avatarIcon}
        <ListItemText
          primary={
            <Box sx={style.headerContainer}>
              <Typography
                variant="body1"
                color="text.primary"
                style={{zIndex: 2}}>
                {note.title}
              </Typography>
            </Box>
          }
          secondary={
            <Box sx={style.subtitleContainer}>
              <Box sx={style.subtitleDate}>
                <Box sx={style.rowContainer}>
                  <CalendarMonthIcon/><Typography>{parseDate(note.creationDate)}</Typography>
                </Box>
                {note.isAudio && note.publicUrl && <Box sx={style.rowContainer}>
                  <TimelapseIcon/><AudioDuration publicUrl={note.publicUrl} showLabel={false}/>
                </Box>
                }
              </Box>
              <Box sx={style.rowContainer}>
                <Typography sx={style.markdownText}>
                  <Markdown>
                    {text}
                  </Markdown>
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    );
  };

  if (note.isFile && !note.isAnImage) {
    return <a
      style={style.link}
      href={note.publicUrl}
      target="_blank"
      rel={'noreferrer'}>
      {listItem()}
    </a>;
  }

  return (
    <Link style={style.link} to={linkToNote}
          state={linkShareInfo}>
      {listItem()}
    </Link>
  );
}

export default observer(SimpleNotebookEntryListItem);

const styles = () => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px'
  },
  rowContainer: {
    display: 'flex',
    gap: '5px'
  },
  markdownText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  titleInput: {
    background: 'none',
    border: 'none',
    fontSize: '1rem'
  },
  subtitleContainer: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  },
  subtitleDate: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  link: {
    display: 'flex',
    marginRight: '10px',
    textDecoration: 'none'
  }
});