import FormTextField from "../../fields/FormTextField";
import FilledTextButton from "../../buttons/FilledTextButton";
import * as React from "react";
import FormSelectField from "../../fields/FormSelectField";
import {observer} from "mobx-react";
import LoadingContainer from "../../loaders/LoadingContainer";

function SelectDoctorAndTimeStep({form, sharingTimeOptionsSelect, onContinue, loading}) {

  const style = styles();

  return (
    <>
      {loading && <LoadingContainer message={"Buscando información del doctor..."}/>}
      {!loading &&
        <>
          <FormTextField
            extraLabelStyle={{padding: 0, background: 'none'}}
            field={form.getFieldByName('email')}
            disabled={loading}
            label={"Email del doctor"}/>
          <FormSelectField field={form.getFieldByName('sharingTime')} label={"Tiempo"}
                           extraLabelStyle={style.labelStyle}
                           disabled={loading}
                           options={sharingTimeOptionsSelect}/>
          <FilledTextButton label="Continuar"
                            style={style.continueButton}
                            onClick={onContinue}
                            disabled={!form.isValid || loading}/>
        </>
      }

    </>
  );
}


export default observer(SelectDoctorAndTimeStep);

const styles = () => ({
  labelStyle: {
    padding: 0,
    background: 'none'
  },
  continueButton: {
    width: 'fit-content',
    alignSelf: 'flex-end'
  }
});