/* eslint-disable no-unused-vars */
import {action, computed, makeObservable, observable} from 'mobx';
import {toast} from 'react-toastify';
import TextField from '../libs/forms/fields/TextField';
import MaiaForm from '../libs/forms/MaiaForm';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';


export default class NotebookFeature {
  constructor(application, voiceAudioRecorder) {
    this._application = application;
    this._voiceAudioRecorder = voiceAudioRecorder;
    this._notificationReceiver = application.notificationReceiver();
    this._notes = [];
    this._tags = [];
    this._isLoading = true;
    this._textNoteForm = this._createTextNoteForm();
    this._showTextNoteForm = false;
    this._showUploadFileForm = false;
    this._showSearchResults = false;
    this._searchedEntries = [];

    makeObservable(this, {
      _notes: observable,
      _showTextNoteForm: observable,
      _showSearchResults: observable,
      _searchedEntries: observable,
      _showUploadFileForm: observable,
      _isLoading: observable,
      _tags: observable,
      searchedEntries: computed,
      notes: computed,
      tags: computed,
      isRetrievingData: computed,
      showTextNoteForm: computed,
      showUploadFileForm: computed,
      createTextNote: action,
      cancelTextNote: action,
      toggleShowTextNoteForm: action,
      toggleShowUploadFieldForm: action,
      _refreshNotebook: action,
      _updateNotes: action,
      _updateTags: action,
      _updateIsLoading: action,
      load: action
    });
  }

  _updateNotes(notes) {
    this._notes = notes;
  }

  async _saveFile(file, responseHandler) {
    throw new Error('Not implemented');
  }

  _updateEntryTitle(entry, responseHandler) {
    throw new Error('Not implemented');
  }

  _updateEntryTags(entry, responseHandler) {
    throw new Error('Not implemented');
  }

  async _saveTextNote(title, content, responseHandler) {
    throw new Error('Not implemented');
  }

  async _refreshNotebook() {
    throw new Error('Not implemented');
  }

  _deleteEntry(entry, responseHandler) {
    throw new Error('Not implemented');
  }

  async load() {
    this._notificationReceiver.subscribeToNotifications(this, 'transcription_finished', this._onTranscriptionFinished);
    this._voiceAudioRecorder.addAudioRecorderListener(() => this._refreshNotebook());

    await this._withLoadingDo(async () => {
      await this._refreshNotebook();
      await this._getTags();
      await this._onLoad();
    });
  }

  async _onLoad() {
    // To be overridden by subclasses
  }

  onUnload = () => {
    this._notificationReceiver.unsubscribeFromAll(this);
    this._voiceAudioRecorder.removeAudioRecorderListener(() => this._refreshNotebook());
  };

  _onTranscriptionFinished = () => {
    toast.success('Transcripción finalizada');
    this._refreshNotebook();
  };

  _createTextNoteForm() {
    const fields = {
      'title': new TextField(2, 100),
      'textNote': new TextField(2, 5000)
    };

    return new MaiaForm(fields);
  }

  deleteAudioNoteAndRefresh = (audioNote) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        await this._withLoadingDo(async () => await this._refreshNotebook());
        toast.success('Nota de audio eliminada correctamente');
      }
    });
    this._deleteEntry(audioNote, responseHandler);
  };

  deleteTextNoteAndRefresh = (textNote) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        await this._withLoadingDo(async () => await this._refreshNotebook());
        toast.success('Nota de texto eliminada correctamente');
      }
    });
    this._deleteEntry(textNote, responseHandler);
  };

  deleteFileAndRefresh = (file) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        await this._withLoadingDo(async () => await this._refreshNotebook());
        toast.success('Archivo eliminado correctamente');
      }
    });
    this._deleteEntry(file, responseHandler);
  }

  uploadFileToNotebook = async (file) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        await this._withLoadingDo(async () => await this._refreshNotebook());
        this.toggleShowUploadFieldForm();
        toast.success('Archivo subido correctamente');
      }
    });
    await this._saveFile(file, responseHandler);
  };

  updateEntryTitle = (entry, name) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        await this._withLoadingDo(async () => await this._refreshNotebook());
      }
    });
    this._updateEntryTitle(entry, name, responseHandler);
  }

  updateEntryTags = (entry, tags) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
      },
      handlesError: () => {
        this._refreshNotebook();
      }
    });
    this._updateEntryTags(entry, tags, responseHandler);
  }

  get notes() {
    return this._notes;
  }

  createTextNote = async () => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: async () => {
        this.showTextNoteForm = false;
        this._textNoteForm.clear();
        await this._withLoadingDo(async () => await this._refreshNotebook());
        toast.success('Nota de texto creada correctamente.');
      }
    });
    const title = this._textNoteForm.getFieldValue('title');
    const content = this._textNoteForm.getFieldValue('textNote');
    await this._saveTextNote(title, content, responseHandler);
  };

  async _getTags() {
    this._application.notebookConfiguration().getTags((tags) => this._updateTags(tags));
  }

  cancelTextNote = () => {
    this._textNoteForm.clear();
    this.showTextNoteForm = false;
  };

  _updateTags(tags) {
    this._tags = tags;
  }

  get textNoteForm() {
    return this._textNoteForm;
  }

  get tags() {
    return this._tags;
  }

  get showTextNoteForm() {
    return this._showTextNoteForm;
  }

  set showTextNoteForm(showTextNoteForm) {
    this._showTextNoteForm = showTextNoteForm;
  }

  get showUploadFileForm() {
    return this._showUploadFileForm;
  }

  set showUploadFileForm(showUploadFileForm) {
    this._showUploadFileForm = showUploadFileForm;
  }

  get isRetrievingData() {
    return this._isLoading;
  }

  async _withLoadingDo(action) {
    this._updateIsLoading(true);
    await action();
    this._updateIsLoading(false);
  }

  toggleShowUploadFieldForm = () => {
    this.showUploadFileForm = !this.showUploadFileForm;
    this.showTextNoteForm = false;
  };

  toggleShowTextNoteForm = () => {
    this.showTextNoteForm = !this.showTextNoteForm;
    this.showUploadFileForm = false;
  }

  closeSearchResults = () => {
    this._showSearchResults = false
  }

  get searchedEntries() {
    return this._searchedEntries;
  }

  get showSearchResults() {
    return this._showSearchResults;
  }

  _updateIsLoading(newState) {
    this._isLoading = newState;
  }
}