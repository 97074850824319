import {observer} from 'mobx-react';
import PlayArrow from '@mui/icons-material/PlayArrow';
import {Avatar, ListItemAvatar, useTheme} from "@mui/material";
import PlayButton from "./PlayButton";
import * as React from "react";

function AvatarPlayButton({audioOwner, audioScr,}) {
  const theme = useTheme();

  return (
    <ListItemAvatar>
      <Avatar sx={{
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <PlayButton
          audioScr={audioScr}
          audioOwner={audioOwner}
          size={32}
          icon={PlayArrow}
          color={theme.colors.white}
        />
      </Avatar>
    </ListItemAvatar>
  )
}

export default observer(AvatarPlayButton);




