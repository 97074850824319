import {Endpoint, SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import GetPatientsResponse from "./responses/GetPatientsResponse";
import GetPatientResponse from "./responses/GetPatientResponse";
import GetPatientMedicalRecordResponse from "./responses/GetPatientMedicalRecordResponse";
import GetTextNoteResponse from "./responses/GetTextNoteResponse";
import GetAudioNoteTranscriptionResponse from "./responses/GetAudioNoteTranscriptionResponse";
import MedicalConsultationResponse from "./responses/MedicalConsultationResponse";
import ProfileResponse from "./responses/ProfileResponse";
import GetNotebookResponse from "./responses/GetNotebookResponse";
import GetNotebookConfigurationResponse from "./responses/GetNotebookConfigurationResponse";
import DoctorProfileResponse from "./responses/DoctorProfileResponse";
import SharePermissionsResponse from "./responses/SharePermissionsResponse";
import GetNotebookEntryResponse from './responses/GetNotebookEntryResponse';
import GetEntriesResponse from "./responses/GetEntriesResponse";

export default class EndpointFactory {
  getDoctorsPatientsEndpoint() {
    return Endpoint.newGet({
      url: "patients",
      ownResponses: [GetPatientsResponse],
    });
  }

  findPatientByIdentificationNumber(identificationNumber) {
    return Endpoint.newGet({
      url: `patients/${identificationNumber}`,
      ownResponses: [GetPatientResponse],
    });
  }

  getAudioNoteTranscriptionEndpoint(id) {
    return Endpoint.newGet({
      url: `notes/${id}/transcription`,
      ownResponses: [GetAudioNoteTranscriptionResponse],
    })
  }

  getNotebookEntry(id) {
    return Endpoint.newGet({
      url: `notebook/entries/${id}`,
      ownResponses: [GetNotebookEntryResponse],
    })
  }

  getTextNoteEndpoint(id) {
    return Endpoint.newGet({
      url: `text_notes/${id}`,
      ownResponses: [GetTextNoteResponse],
    })
  }

  editTextNoteContentEndpoint(id) {
    return Endpoint.newPatch({
      url: `text_notes/${id}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  getNotebookEntriesEndpoint() {
    return Endpoint.newGet({
      url: "notebook",
      ownResponses: [GetNotebookResponse],
    });
  }

  getEntriesBySearchEndpoint(searchQuery) {
    return Endpoint.newGet({
      url: `notebook/entries/similar?query=${searchQuery}`,
      ownResponses: [GetEntriesResponse],
    });
  }

  searchMedicalRecordEntriesEndpoint(patientId) {
    return Endpoint.newGet({
      url: `patient/${patientId}/medical_record/similar`,
      ownResponses: [GetEntriesResponse],
    });
  }

  deleteNotebookEntryEndpoint(entryId) {
    return Endpoint.newDelete({
      url: `notebook/entries/${entryId}`,
      ownResponses: [SuccessfulApiResponse],
    });
  }

  deleteMedicalRecordEntryEndpoint(patientId, entryId) {
    return Endpoint.newDelete({
      url: `patient/${patientId}/medical_record/entries/${entryId}`,
      ownResponses: [SuccessfulApiResponse],
    });
  }

  getPatientMedicalRecordEndpoint(patientId) {
    return Endpoint.newGet({
      url: `patient/${patientId}/medical_record`,
      ownResponses: [GetPatientMedicalRecordResponse],
    });
  }

  createAudioNoteEndpoint() {
    return Endpoint.newPost({
      url: "notebook/entries/audio_notes",
      contentType: 'multipart/form-data',
    });
  }

  createTextNoteEndpoint() {
    return Endpoint.newPost({
      url: "notebook/entries/text_notes"
    });
  }

  saveFileToNotebookEndpoint() {
    return Endpoint.newPost({
      url: "notebook/files",
      contentType: 'multipart/form-data',
    });
  }

  createMedicalRecordAudioNoteEndpoint(patientId) {
    return Endpoint.newPost({
      url: `patient/${patientId}/medical_record/audio_notes`,
      contentType: 'multipart/form-data',
    });
  }

  createMedicalRecordTextNoteEndpoint(patientId) {
    return Endpoint.newPost({
      url: `patient/${patientId}/medical_record/text_notes`,
    });
  }

  saveFileToMedicalRecordEndpoint(patientId) {
    return Endpoint.newPost({
      url: `patient/${patientId}/medical_record/files`,
      contentType: 'multipart/form-data',
    });
  }

  createMedicalConsultationEndpoint() {
    return Endpoint.newPost({
      url: `medical_consultation`,
      ownResponses: [MedicalConsultationResponse]
    });
  }

  createFirstMedicalConsultationEndpoint() {
    return Endpoint.newPost({
      url: "medical_consultation/for_new_patient",
      ownResponses: [MedicalConsultationResponse]
    });
  }

  loginDoctorEndpoint() {
    return Endpoint.newPost({
      url: `doctor_access`,
      ownResponses: [ProfileResponse],
      needsAuthorization: false,
    });
  }

  loginPatientEndpoint() {
    return Endpoint.newPost({
      url: `patient_access`,
      ownResponses: [ProfileResponse],
      needsAuthorization: false,
    });
  }

  updateNotebookEntryTitleEndpoint(entry) {
    return Endpoint.newPatch({
      url: `notebook/entries/${entry.shared_id}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  updateNotebookEntryTagsEndpoint(entry) {
    return Endpoint.newPatch({
      url: `notebook/entries/${entry.shared_id}/tags`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  updateMedicalRecordEntryTitleEndpoint(entry, identificationNumber) {
    return Endpoint.newPatch({
      url: `patient/${identificationNumber}/medical_record/entries/${entry.shared_id}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  updateMedicalRecordEntryTagsEndpoint(entry, identificationNumber) {
    return Endpoint.newPatch({
      url: `patient/${identificationNumber}/medical_record/entries/${entry.shared_id}/tags`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  getNotebookConfigurationEndpoint() {
    return Endpoint.newGet({
      url: "notebook_configuration",
      ownResponses: [GetNotebookConfigurationResponse],
    });
  }

  createNotebookTagEndpoint() {
    return Endpoint.newPost({
      url: "notebook_configuration/tags",
      ownResponses: [SuccessfulApiResponse]
    });
  }

  deleteNotebookTagEndpoint(tagId) {
    return Endpoint.newDelete({
      url: `notebook_configuration/tags/${tagId}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  editNotebookTagEndpoint(tagId) {
    return Endpoint.newPatch({
      url: `notebook_configuration/tags/${tagId}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  updatePatientProfileEndpoint() {
    return Endpoint.newPatch({
      url: "profile",
      ownResponses: [SuccessfulApiResponse]
    });
  }

  getPatientProfileEndpoint() {
    return Endpoint.newGet({
      url: "profile",
      ownResponses: [ProfileResponse],
    });
  }

  getDoctorInformationEndpoint() {
    return Endpoint.newGet({
      url: `doctors`,
      ownResponses: [DoctorProfileResponse],
    });
  }

  shareNotebookWithDoctorEndpoint() {
    return Endpoint.newPost({
      url: "notebook/share_permissions",
      ownResponses: [SuccessfulApiResponse]
    });
  }

  stopSharingNotebookWithDoctorEndpoint(doctorEmail) {
    return Endpoint.newPost({
      url: `notebook/share_permissions/${doctorEmail}`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  markNotebookEntryAsPrivateEndpoint(entry) {
    return Endpoint.newPatch({
      url: `notebook/entries/${entry.shared_id}/private`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  markNotebookEntryAsPublicEndpoint(entry) {
    return Endpoint.newPatch({
      url: `notebook/entries/${entry.shared_id}/public`,
      ownResponses: [SuccessfulApiResponse]
    });
  }

  getSharePermissionsEndpoint() {
    return Endpoint.newGet({
      url: "notebook/share_permissions",
      ownResponses: [SharePermissionsResponse]
    });
  }

  getPatientSharedNotebookEndpoint(patientId) {
    return Endpoint.newGet({
      url: `patient/${patientId}/shared-notebook`,
      ownResponses: [GetNotebookResponse]
    });
  }

  getSharedEntryEndpoint(patientId, entryId) {
    return Endpoint.newGet({
      url: `patient/${patientId}/shared-notebook/entries/${entryId}`,
      ownResponses: [GetNotebookEntryResponse]
    });
  }
}