import { observer } from 'mobx-react';
import { Avatar, ListItemAvatar, useTheme } from '@mui/material';
import * as React from 'react';
import { StickyNote2 } from '@mui/icons-material';

function AvatarTextNote() {
  const theme = useTheme();

  return (
    <ListItemAvatar>
      <Avatar sx={{
        background: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <StickyNote2 />
      </Avatar>
    </ListItemAvatar>
  )
}

export default observer(AvatarTextNote);




